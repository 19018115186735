import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "common-layout"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "prestissimo-navbar mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitNavbar = _resolveComponent("MrUiKitNavbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MrUiKitAlert = _resolveComponent("MrUiKitAlert")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_ctx.isMounted && _ctx.manageItems.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_MrUiKitNavbar, {
              logo: _ctx.logoHeader,
              items: _ctx.tabs,
              headerClasses: ['navbar-height-radius'],
              onNavigate: _ctx.onNavigate
            }, null, 8, ["logo", "items", "onNavigate"])
          ]),
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_MrUiKitAlert, {
          modelValue: _ctx.alert.toggle,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.alert.toggle) = $event)),
          timeout: 4000,
          type: _ctx.alert.type,
          classes: ['position-fixed', 'alert-position', 'w-25']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.alert.message), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "type"]),
        _createVNode(_component_Footer)
      ]))
    : _createCommentVNode("", true)
}