
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import { MrUiKitNavbar, MrUiKitAlert } from "mr-ui-kit";
import Footer from "@/components/UI/Footer.vue";
import { useRoute, useRouter } from "vue-router";
import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";
import { UserRole } from "@/interfaces";
import { useStore } from "vuex";

export default defineComponent({
  name: "CommonLayout",
  components: {
    MrUiKitNavbar,
    MrUiKitAlert,
    Footer,
  },
  setup() {
    const router = useRouter();
    const isMounted = ref(false);
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      isMounted.value = true;
    });

    const Allitems = reactive([
      {
        label: "Home",
        path: "/",
        allowed: [UserRole.Admininistrator, UserRole.Dealer, UserRole.NoDealer],
        active: false,
      },
      {
        label: "Lista esercenti",
        path: "/dealers-list",
        allowed: [UserRole.Admininistrator],
        active: false,
      },
      {
        label: "Storico richieste",
        path: "/requests-list",
        allowed: [UserRole.Admininistrator, UserRole.Dealer, UserRole.NoDealer],
        active: false,
      },
      {
        label: "Esci",
        path: "/logout",
        icon: { name: "bi bi-box-arrow-in-right", zoom: 1 },
        allowed: [UserRole.Admininistrator, UserRole.Dealer, UserRole.NoDealer],
        active: false,
      },
      {
        label: "Nuova Richiesta",
        path: "/new-request",
        allowed: [UserRole.Dealer, UserRole.NoDealer],
        classes: ["btn-primary"],
        active: false,
      },
    ]);

    const manageItems = computed(() => {
      const tabs = Allitems.map((t) => {
        return {
          ...t,
          active: route.path === t.path && route.path !== "/new-request",
        };
      });

      return tabs.filter((item) =>
        item.allowed.includes(UserService.getUser().role)
      );
    });

    const onNavigate = (event: any) => {
      if (event === "/logout") {
        AuthService.logout();
      } else {
        router.push(event);
      }
    };

    return {
      tabs: manageItems,
      manageItems,
      onNavigate,
      alert: store.getters.alertCfg,
      logoHeader: require("@/assets/img/main/logo_header.png"),
      isMounted,
    };
  },
});
